<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text"
                          filled
                          label="Власник посвідчення"
                          :value="selected_privilege.privilege_owner_name"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text"
                          filled
                          label="Категорія пільги"
                          :value="selected_privilege.privilege_category_name"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text"
                          filled
                          label="Пільговиків (чол.)"
                          v-model.number="persons"
                          hide-details
                          v-decimal
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_start" req label="Дата початку" :class_="!date_start ? 'req-star' : ''"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_end" label="Дата закінчення"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  REMOVE_PRIVILEGE_PERSONS,
  UPDATE_PRIVILEGE_PERSONS,
  CREATE_PRIVILEGE_PERSONS
} from "@/store/actions/privilege";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'privilege_category_person_modal_delete'

export default {
  name: "HWP_Modal_PrivilegePerson",
  mixins: [ModalComponentMixin],
  data() {
    return {
      formValid: false,
      watcher: null,
      delete_watcher: null,
      dialog_watcher: null,
      itemId: this.item.id,
      date_start: this.item.date_start,
      date_end: this.item.date_end,
      persons: this.item.persons || 0,
      isNew: this.item.id === undefined,
      menu: false,
    }
  },
  methods: {
    changePrivilegeCategory(payload) {
      this.privilege_category_id = payload ? payload.value : null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.persons = this.item.persons || 0

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення пільговиків: ${this.persons} чол.`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Назва має бути заповнена',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        privilege_id: this.selected_privilege.id,
        date_start: this.date_start,
        date_end: this.date_end || null,
        persons: this.persons || 0,
        flat_id: this.selected_privilege.flat_id || null
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_PRIVILEGE_PERSONS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_PRIVILEGE_PERSONS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.date_start = payload.date_start
              this.date_end = payload.date_end || null
              this.persons = payload.persons || 0
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_PRIVILEGE_PERSONS, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      selected_privilege: 'getPrivilegeSelected',
    }),
  }
}
</script>

